<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', { nickname: myInfo.user_nickname })"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="this.$router.back()">
          <img src="../../../assets/img/btn_back.png">
        </a>{{ $t('charge.title') }}
      </div>
      <div class="mypage_point">
        <b>{{ currency(myInfo.points) }}</b>{{ $t('common.point') }}
      </div>
    </div>

    <div class="warp">
      <h2>{{ $t('charge.guide_title') }}</h2>
      <br />
      <div class="guide_text" v-html="$t('charge.guide_text1')" />
      <div class="guide_text" v-html="$t('charge.guide_text2')" />
      <br />
      <br />
      
      <h2>{{ $t('charge.select_item') }}</h2>
      <br />
      <div class="charge_list">
        <ul>
          <li @click="onJpPay(4)">
            <img src="../../../assets/img/charge_item4_icon.png" style="width:50px;height:50px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start; margin-left: 20px;flex-grow: 1;">
              <span class="point_1">{{ $t('charge.item4_hint') }}</span>
              <span class="point_2">{{ currency(chargeItem[4].POINT) }} P</span>
            </div>
            <span class="price_1">¥ 25,500</span>
            <img src="../../../assets/img/charge_discount_arrow.png" style="width:80px;height:24px;position: absolute;top:24px;right:25px;">
            <span class="price_2">¥ {{ currency(chargeItem[4].PRICE_JP) }}</span>
            <div class="flag">
              <img src="../../../assets/img/charge_item4_badge.png" style="width:150px;height:34px;position: absolute;top:-4px;left:-8px;">
            </div>
          </li>
          <li @click="onJpPay(3)">
            <img src="../../../assets/img/point_05.png" style="width:50px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start; margin-left: 20px;flex-grow: 1;">
              <span class="point_1">{{ $t('charge.item3_hint') }}</span>
              <span class="point_2">{{ currency(chargeItem[3].POINT) }} P</span>
            </div>
            <span class="price_1">¥ 13,000</span>
            <img src="../../../assets/img/charge_discount_arrow.png" style="width:80px;height:24px;position: absolute;top:24px;right:25px;">
            <span class="price_2">¥ {{ currency(chargeItem[3].PRICE_JP) }}</span>
            <div class="flag">
              <img src="../../../assets/img/charge_item3_badge.png" style="width:80px;height:30px;position: absolute;top:-4px;left:-2px;">
            </div>
          </li>
          <li @click="onJpPay(2)">
            <img src="../../../assets/img/point_03.png" style="width:50px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start; margin-left: 20px;flex-grow: 1;">
              <span class="point_1">{{ $t('charge.item2_hint') }}</span>
              <span class="point_2">{{ currency(chargeItem[2].POINT) }} P</span>
            </div>
            <span class="price_1">¥ 6,500</span>
            <img src="../../../assets/img/charge_discount_arrow.png" style="width:80px;height:24px;position: absolute;top:24px;right:25px;">
            <span class="price_2">¥ {{ currency(chargeItem[2].PRICE_JP) }}</span>
          </li><br/>
          <li @click="onJpPay(1)">
            <img src="../../../assets/img/point_02.png" style="width:50px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start; margin-left: 20px;flex-grow: 1;">
              <span class="point_1">{{ $t('charge.item1_hint') }}</span>
              <span class="point_2">{{ currency(chargeItem[1].POINT) }} P</span>
            </div>
            <span class="price_1">¥ 4,000</span>
            <img src="../../../assets/img/charge_discount_arrow.png" style="width:80px;height:24px;position: absolute;top:24px;right:25px;">
            <span class="price_2">¥ {{ currency(chargeItem[1].PRICE_JP) }}</span>
            <div class="flag">
              <img src="../../../assets/img/charge_item1_badge.png" style="width:60px;height:60px;position: absolute;top:-4px;left:-10px;">
            </div>
          </li>
          <li @click="onJpPay(0)">
            <img src="../../../assets/img/point_01.png" style="width:50px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start; margin-left: 20px;flex-grow: 1;">
              <span class="point_1">{{ $t('charge.item0_hint') }}</span>
              <span class="point_2">{{ currency(chargeItem[0].POINT) }} P</span>
            </div>
            <span class="price_1">¥ 890</span>
            <img src="../../../assets/img/charge_discount_arrow.png" style="width:60px;height:24px;position: absolute;top:24px;right:25px;">
            <span class="price_2">¥ {{ currency(chargeItem[0].PRICE_JP) }}</span>
          </li>
        </ul>
      </div>
      <div class="clear">
      </div>
    </div>
  </div>

  <charge-popup v-if="showChargePopup" :itemIndex="selectedItemIndex" @close="showChargePopup=false" />
</template>

<style lang="scss" scoped src="./Charge.scss"></style>

<script>
import { showToast, currency, requestPost } from "../../../lib/util";
import { EVENT_NAME, CHARGE_ITEM, VIP_ITEM } from "../../../lib/constant";
import ChargePopup from "./ChargePopup.vue";

export default {
  name: 'Charge',
  components: {
    ChargePopup,
  },
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      chargeItem: CHARGE_ITEM,
      showChargePopup: false,
      selectedItemIndex: 0,
    }
  },
  mounted() {
    this.emitter.on(EVENT_NAME.CHARGE_PAGE_RESUME, this.onPointUpdate);
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.CHARGE_PAGE_RESUME, this.onPointUpdate);
  },
  methods: {
    onJpPay: function(index) {
      this.selectedItemIndex = index;
      this.showChargePopup = true;
    },
    onPointUpdate: function (data) {
      let _app = this;
      let originalPoint = this.myInfo.points;

      console.log('Charge.vue onPointUpdate()');
      requestPost(
        'user/getUserCash',
        {},
        response => {
          console.log('Charge.vue onPointUpdate() getUserCash:', response.points, originalPoint);
          if (response.points > originalPoint) {
            _app.myInfo.points = response.points;
            _app.$store.dispatch('setMyInfo', _app.myInfo);

            showToast(_app.$t('charge.purchase_success', { nickname: _app.myInfo.user_nickname, point: response.points - originalPoint }))
          }
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  },
}
</script>
