<template>
  <transition name="fade">
    <div class="pop_back" @click="$emit('close')">
      <div class="pop_charge" @click="$event.stopPropagation()">
        <div class="left">
          <span class="item_label">{{ $t('charge.item_select') }}</span>
          <select v-model="selectedItemIndex">
            <option v-for="(it, idx) in chargeItem" :key="idx" :value="idx">
              <span>{{ currency(it.POINT) }} P</span>
            </option>
          </select>
          <span class="price_label">¥ {{ currency(chargeItem[selectedItemIndex].PRICE_JP) }}</span>
          <div style="flex-grow: 1;"/>
          <a class="close_button" href="javascript:" @click="$emit('close')">{{ $t('common.close') }}</a>
          <a class="purchase_button" href="javascript:" @click="onPurchase()">{{ $t('charge.charge_purchase') }}</a>
        </div>
        
        <div class="right">
          <h2>{{ $t('charge.select_pg') }}</h2>
          <br />
          <div class="pg_list">
            <ul>
              <a href="javascript:" @click="onPGClick(0)">
                <li :class="{ selected: pgIndex == 0 }">
                  <img class="pg_image" src="../../../assets/img/pg_card.png">
                  <h2>{{ $t('charge.card') }}</h2>
                </li>
              </a>
              <a href="javascript:" @click="onPGClick(1)">
                <li :class="{ selected: pgIndex == 1 }">
                  <img class="pg_image" src="../../../assets/img/pg_tele_d.png">
                  <h2>{{ $t('charge.tele_d') }}</h2>
                </li>
              </a>
              <a href="javascript:" @click="onPGClick(2)">
                <li :class="{ selected: pgIndex == 2 }">
                  <img class="pg_image" src="../../../assets/img/pg_tele_au.png">
                  <h2>{{ $t('charge.tele_au') }}</h2>
                </li>
              </a>
              <a href="javascript:" @click="onPGClick(3)">
                <li :class="{ selected: pgIndex == 3 }">
                  <img class="pg_image" src="../../../assets/img/pg_tele_softbank.png">
                  <h2>{{ $t('charge.tele_softbank') }}</h2>
                </li>
              </a>
            </ul>
          </div>

          <br /><br />

          <div v-if="pgIndex == 0" class="card_info_box">
            <h2>{{ $t('charge.card_info') }}</h2>
            <br />
            <div class="card-js" id="cardInfo" data-capture-name="true"></div>
            <br /><br />
          </div>
        </div>
      </div>
    </div>
  </transition>

  <form id="card_pay_form" method="get" target="_blank">
  </form>

  <form id="softbank_pay_form" method="post" target="_blank">
    <input id="softbank_access_id" name="AccessID" type="hidden">
    <input id="softbank_token" name="Token" type="hidden">
  </form>

  <form id="dpay_pay_form" method="post" target="_blank">
    <input id="dpay_access_id" name="AccessID" type="hidden">
    <input id="dpay_token" name="Token" type="hidden">
  </form>
</template>

<style lang="scss" scoped src="./ChargePopup.scss"></style>

<script>

import { EVENT_NAME, CHARGE_ITEM, VIP_ITEM } from "../../../lib/constant";
import { requestPost, showToast } from "../../../lib/util";

export default {
  name: 'ChargePopup',
  components: {},
  props: {
    itemIndex: {
      type: Number,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'report'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      chargeItem: CHARGE_ITEM,
      selectedItemIndex: this.itemIndex,
      backButtonRouteGuard: null,
      pgIndex: 0,

      // Card
      // cardNo: '5312 8995 6087 0088',
      // cardExpireYear: '24',
      // cardExpireMonth: '06',
      // cardHolderName: 'SANGWOO JUNG',
      // cardSecurityCode: '178',
      cardNo: '',
      cardExpireYear: '',
      cardExpireMonth: '',
      cardHolderName: '',
      cardSecurityCode: '',
    }
  },
  mounted() {
    this.emitter.on(EVENT_NAME.PURCHASE_SUCCESS, this.onPurchaseSuccess);
    this.emitter.on(EVENT_NAME.CHARGE_PAGE_RESUME, this.onPointUpdate);

    this.initializeCardInput();
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.PURCHASE_SUCCESS, this.onPurchaseSuccess);
    this.emitter.off(EVENT_NAME.CHARGE_PAGE_RESUME, this.onPointUpdate);
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_charge').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    initializeCardInput() {
      console.log('ChargePopup.initializeCardInput() called!');
      $(".card-js").each((i, obj) => {
        $(obj).CardJs();

        $(".card-js").data('cardjs').cardNumberInput.val(this.cardNo);
        $(".card-js").data('cardjs').nameInput.val(this.cardHolderName);
        $(".card-js").data('cardjs').expiryMonthInput.val(this.cardExpireMonth);
        $(".card-js").data('cardjs').expiryYearInput.val(this.cardExpireYear);
        $(".card-js").data('cardjs').cvcInput.val(this.cardSecurityCode);
      });
    },
    onPGClick: function (index) {
      this.pgIndex = index;

      if (index == 0) {
        setTimeout(() => {
          this.initializeCardInput();
        }, 10);
      }
    },
    onPurchase: function () {
      if (this.pgIndex == 0) {
        this.cardPay(this.itemIndex);
      } else if (this.pgIndex == 1) {
        this.dpay(this.itemIndex);
      } else if (this.pgIndex == 3) {
        this.softbank(this.itemIndex);
      }
    },
    onPurchaseSuccess: function (data) {
      showToast(this.$t('charge.purchase_success', { nickname: this.myInfo.user_nickname, point: data.paidPoint }))
    },
    onPointUpdate: function (data) {
      let _app = this;
      let originalPoint = this.myInfo.points;

      console.log('ChargePopup.vue onPointUpdate()');
      requestPost(
        'user/getUserCash',
        {},
        response => {
          console.log('ChargePopup.vue onPointUpdate() getUserCash:', response.points, originalPoint);
          if (response.points > originalPoint) {
            _app.myInfo.points = response.points;
            _app.$store.dispatch('setMyInfo', _app.myInfo);

            showToast(_app.$t('charge.purchase_success', { nickname: _app.myInfo.user_nickname, point: response.points - originalPoint }))
          }
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    cardPay: async function (index) {
      this.cardNo = $("#cardInfo").CardJs('cardNumber').replaceAll(' ', '');
      this.cardExpireYear = `${$("#cardInfo").CardJs('expiryYear').padStart(2,'0')}`;
      this.cardExpireMonth = `${$("#cardInfo").CardJs('expiryMonth').padStart(2,'0')}`;
      this.cardHolderName = $("#cardInfo").CardJs('name');
      this.cardSecurityCode = $("#cardInfo").CardJs('cvc');

      if (this.cardNo == '') {
        showToast(this.$t('charge.input_card_no'));
        return;
      }
      if (this.cardHolderName == '') {
        showToast(this.$t('charge.input_card_holder_name'));
        return;
      }
      if (this.cardExpireYear == '' || this.cardExpireMonth == '') {
        showToast(this.$t('charge.input_card_expire'));
        return;
      }
      if (this.cardSecurityCode == '') {
        showToast(this.$t('charge.input_card_security_code'));
        return;
      }

      Multipayment.init('9200006777871');	 // ショップID
      
      let _app = this;
      Multipayment.getToken(
        { 
          cardno: this.cardNo,   // カード番号
          expire: this.cardExpireYear + this.cardExpireMonth,             // カード有効期限
          securitycode: this.cardSecurityCode,          // セキュリティコード
          holdername: this.cardHolderName,    // カード名義人
          tokennumber: '1'              // MPクレカトークン発行数
        }, 
        (result) => {
          // 処理結果コードが'000'以外の時はエラーのためダイアログを表示する
          // 
          // [2024/03/04 14:42 KSH]응답자료 샘플.
          //
          //{
          //     "tokenObject": {
          //         "maskedCardNo": "531289******0088",
          //         "toBeExpiredAt": "2024-03-04-16-15-14",
          //         "isSecurityCodeSet": true,
          //         "token": [
          //             "d1961f57143d4d0a1c3cc6c2a97c601af148872637c239474257816c5558cfe4"
          //         ]
          //     },
          //     "resultCode": "000"
          // }
          //
          if( result.resultCode != '000' ){
            window.alert('購入処理中にエラーが発生しました')
          } else {
            requestPost(
              'jpmulpay/cardPay/index',
              {
                uid: _app.myInfo.uid,
                index: index,
                token: result.tokenObject.token[0],
              },
              response => {
                console.log('cardpay redirectUrl:', response.redirectUrl);
                //
                // [2024/03/04 15:14 KSH]샘플 데이터. 
                // https://manage.tds2gw.gmopg.jp/api/v2/brw/callback?transId=c913d325-d20d-45d9-a7c0-e24eee61208e&t=WE2gDHKlYD8h1Uehq1un1hsha0Ao5i42ivHIzXrxYmw2gan8kNcNV6dUxZQdIPUy1NJ061pj
                //

                let index = response.redirectUrl.indexOf("?");
                let action = response.redirectUrl.slice(0, index);
                let params = response.redirectUrl.slice(index);
                let url = new URLSearchParams(params);
                for (let param of url.keys()){
                    let paramValue = url.get(param);
                    let attrObject = {"type":"hidden", "name":param, "value":paramValue};
                    let hidden = $("<input>").attr(attrObject);
                    $("#card_pay_form").append(hidden);
                }

                $("#card_pay_form").attr('action', action);
                $("#card_pay_form").submit();

                _app.$emit('close');
              },
              (code, msg) => {
                showToast(msg);
              }
            );
          }
        }
      );
    },

    softbank: async function (index) {
      let _app = this;
      requestPost(
        'jpmulpay/softbank/index',
        {
          index: index,
        },
        response => {
          $("#softbank_pay_form").attr('action', response.StartURL);
          $("#softbank_access_id").val(response.AccessID);
          $("#softbank_token").val(response.Token);
          $("#softbank_pay_form").submit();

          _app.$emit('close');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    
    dpay: async function (index) {
      let _app = this;
      requestPost(
        'jpmulpay/dpay/index',
        {
          index: index,
        },
        response => {
          $("#softbank_pay_form").attr('action', response.StartURL);
          $("#softbank_access_id").val(response.AccessID);
          $("#softbank_token").val(response.Token);
          $("#softbank_pay_form").submit();

          _app.$emit('close');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
